import AppBar from '@mui/material/AppBar'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

export default () => (
  <AppBar position='relative'>
    <Toolbar>
      <AccessTimeFilledIcon sx={{ mr: 2 }} />
      <Typography variant='h6' color='inherit' noWrap>
        The Waitless Company
      </Typography>
    </Toolbar>
  </AppBar>
)
