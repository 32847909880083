import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function Copyright () {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © The Waitless Company 2022 - '}
      {new Date().getFullYear()}
      .
    </Typography>
  )
}

export default function Footer () {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component='footer'>
      <Typography variant='h6' align='center' gutterBottom>
        The Waitless Company
      </Typography>
      <Typography
        variant='subtitle1'
        align='center'
        color='text.secondary'
        component='p'
      >
        Thanks for checking us out, we're a very small team of excited and impatient of diners like you!
      </Typography>
      <Copyright />
    </Box>
  )
}
