import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Footer from '../../components/Footer'
import TopBar from '../../components/TopBar'

export default function HomePage () {
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(event.currentTarget)
    const data = new FormData(event.currentTarget)
    console.log({
      email: data.get('email')
    })
  }

  return (
    <>
      <CssBaseline />
      <TopBar />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            backgroundImage: "url('images/hero.webp')",
            bgcolor: 'background.paper',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: 8,
            pb: 6
          }}
        >
          <Container
            maxWidth='sm'
            sx={{
              borderRadius: '10px',
              background: 'rgba(255, 255, 255, 0.75)',
              backdropFilter: 'blur(8px)',
              WebkitBackdropFilter: 'blur(8px)'
            }}
          >
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              Experience <em>Waitlessness</em>
            </Typography>
            <Typography variant='h5' align='center' color='text.secondary' paragraph>
              Stop spending time waiting in lines and make informed decisions about where to dine
              ahead of time, so that you know what to expect.
            </Typography>
            <Typography variant='h5' align='center' color='text.secondary' paragraph>
              Be among the first to experience <em>waitlessness</em> with the Waitless App using intelligent
              diner data crowdsourced from thousands of local diners.
            </Typography>
            <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Typography variant='p' align='center' color='text.secondary' paragraph>
                Sign up with your email address to get notified when the app is available, as well as a few
                updates along the way.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                  />
                </Grid>
              </Grid>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            </Box>
          </Container>
        </Box>
      </main>
      <Footer />
    </>
  )
}
