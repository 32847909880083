import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import NotFound from './pages/NotFound'
import Error from './pages/Error'

import Home from './pages/home'

const theme = createTheme({
  palette: {
    primary: {
      main: '#a11b06'
    }
  }
})

const AppRoutes = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/' index element={<Home />} />
        <Route path='error' element={<Error />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
)

export default AppRoutes
