import React from 'react'

const NotFound = () => (
  <>
    <h1>Not Found</h1>
    <div>
      this page hasn't been created yet :(
    </div>
  </>
)

export default NotFound
