import React from 'react'

const Error = () => (
  <>
    <h1>We're sorry. :(</h1>
    <h3>We've encountered an error.</h3>
    <p>You can <a href='/'>try starting over</a> or email us at <a href='mailto:support@livewaitless.com'>support@livewaitless.com</a></p>
  </>
)

export default Error
